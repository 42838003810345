import { SlideNumber, initializeSliderOnView } from './slide-func.js';

document.addEventListener("DOMContentLoaded", function() {
  $(function () {
    const projectsSliderConfig = {
      perPage: 5,
      perMove: 5,
      pagination: true,
      gap: "20px",
      interval: 3000,
      breakpoints: {
        4000: {
          perPage: 4,
          perMove: 4,
        },
        2000: {
          perPage: 3,
          perMove: 3,
        },
        1279: {
          perPage: 2,
          perMove: 2,
        },
        767: {
          pagination: false,
          perPage: 2,
          perMove: 2,
        },
        575: {
          pagination: false,
          perPage: 1,
          perMove: 1,
          padding: {
            right: 80,
          },
        },
      },
    };

    const projectsSliderAloneConfig = {
      perPage: 4,
      perMove: 4,
      pagination: true,
      gap: "20px",
      interval: 3000,
      breakpoints: {
        1279: {
          perPage: 3,
          perMove: 3,
        },
        1000: {
          perPage: 3,
          perMove: 3,
        },
        767: {
          pagination: false,
          perPage: 2,
          perMove: 2,
        },
        575: {
          pagination: false,
          perPage: 1,
          perMove: 1,
          padding: {
            right: 80,
          },
        },
      },
    };

    if ($("[data-projects]").length > 0) {
      $("[data-projects] [data-projects-slider]").each(function () {
        const sliderId = $(this).attr("id");
        initializeSliderOnView('.servicesPromoBox', `#${sliderId}`, projectsSliderConfig, SlideNumber);
      });

      $("[data-projects-slider-alone]").each(function () {
        const sliderId = $(this).attr("id");
        initializeSliderOnView('.servicesPromoBox', `#${sliderId}`, projectsSliderAloneConfig, SlideNumber);
      });
    }
  });
});
